import React from "react"
import Page from '../components/Page'
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import { getActiveLanguage } from "../utils/helper"
import './errorpage.scss';
import { StaticImage } from "gatsby-plugin-image"

export default function Page404({ location }) {
     const meta ={
          title : '',
          description : 'Data is gathered automatically and available for analysis in popular business analytics platforms.'
     }
  return (
    <Layout meta={meta}location={location} lang={getActiveLanguage(location)}>
     <div className="errorpage-section">
          <div className="container">
               <div className="errorpage-content">
                    <div className="error-hero">
                      <h1 className="text-danger">404</h1>
                      <p>Oooops!<br /> You’re looking for the page that was moved or doesn’t exist.</p>
                    </div>
                    <div className="home-btn">
                         <a href='/'>Go Home</a>
                    </div>
                    <div className="more-place">
                          <h4>Few more places to visit:</h4>
                          <div className="place-banner">
                                <a className="place-banner-bg">
                                      <div className="place-box">
                                           <div className="place-img">
                                                <StaticImage src="../images/documentation-img.png"/>
                                           </div>
                                           <div className="place-info">
                                                 <h6>Documentation</h6>
                                                 <p>Here you can find a lot of useful info on how to use our service</p>
                                           </div>
                                      </div>
                                </a>
                          </div>  
                    </div>
               </div> 
          </div>
     </div>
    </Layout>
  )
}