import React, { useContext } from "react"
import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import { GlobalContext } from "../context"

const Page = ({ blok, location }) => {

  const content =
    Array.isArray(blok?.body) &&
    blok?.body.map(childBlok => <StoryblokComponent blok={{...childBlok, location:location}} key={childBlok._uid}/>)
  const hasTitle = blok?.title && blok?.title.length ? (<h1 className="text-5xl font-bold font-serif text-primary tracking-wide text-center py-8">{ blok?.title }</h1>) : null
  return (
    <div {...storyblokEditable(blok)}>
             <div className='etl-products'> 
                    {/* { hasTitle } */}
                    { content }
             </div>
    </div>
  )
}

export default Page